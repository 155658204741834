import { render, staticRenderFns } from "./FloatingMenu.vue?vue&type=template&id=29fab2f6&"
import script from "./FloatingMenu.vue?vue&type=script&lang=ts&"
export * from "./FloatingMenu.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports